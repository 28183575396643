<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  أضافة جلسة">
          <b-row>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="subject"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> العنوان</label>
                  <b-form-input
                    v-model="subject"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
         
                <b-form-group label-for="type">
                  <label> النوع</label>
                  <v-select
                  v-model="type"
                 
                  :options="optionType"
               
                />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="kind"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> التصنيف</label>
         
                  <v-select
                  v-model="kind"
                 
                  :options="optionKind"
               
                />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
             
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> المدرب</label>

                  <v-select
                    v-model="trainer_id"
                    :reduce="(val) => val.value"
                    :options="optionUser"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ  </label>
                  <flat-pickr
                    v-model="date"
                    class="form-control"
                    placeholder="حدد تاريخ "
                    rules="required"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              xl="12"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules=""
              >
                <b-form-group label-for="subject">
                  <label> الوصف</label>
                  <b-form-textarea
                    v-model="description"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              xl="12"
            >
              <validation-provider
                #default="{ errors }"
                name="session_report"
                rules=""
              >
                <b-form-group label-for="subject">
                  <label> تقرير الجلسة</label>
                  <b-form-textarea
                    v-model="session_report"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              xl="2"
              v-if="
                  userData.permissions.some(
                    (el) => el['subject'] === 'Psea'
                  )
              "
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="is_psea"
                  plain
                  class="custom-control-warning"
                >
                  <label> is Psea </label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="4"
              xl="4"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="townbycountry">
                <label> البلد</label>
                <v-select
                  v-model="country"
                  :reduce="(val) => val.value"
                  :options="optionCountry"
                  @input="getoptionTgovernorate(country)"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col
              md="4"
              xl="2"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> المحافظة</label>
                <v-select
                  v-model="governorate"
                  :reduce="(val) => val.value"
                  :options="optionTgovernorate"
                  @input="getoptionTCity(governorate)"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col
              md="4"
              xl="2"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="townbycity">
                <label> المنطقة</label>
                <v-select
                  v-model="city"
                  :reduce="(val) => val.value"
                  :options="optionCity"
                  @input="getoptionTArea(city)"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col
              md="3"
              xl="2"
            >
              <b-form-group label-for="townbyvillage">
                <label> الناحية</label>
                <v-select
                  v-model="town"
                  :reduce="(val) => val.value"
                  :options="optionTVillages"
                  @input="getoptionTown(town)"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              xl="2"
            >
              <b-form-group label-for="newtown">
                <label> الحي</label>
                <v-select
                  id="input-default"
                  v-model="neighborhood_id"
                  :reduce="(val) => val.value"
                  :options="optionTown"
                  @input="getoptionStreet(neighborhood_id)"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              xl="2"
            >
              <b-form-group label-for="newtown">
                <label> تفاصيل الحي</label>
                <v-select
                  id="input-default"
                  v-model="street_id"
                  :reduce="(val) => val.value"
                  :options="optionStreet"
                />
              </b-form-group>
            </b-col>
            <b-row>
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="addSession"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> اضافة </span>
                </b-button>
              </b-col>
            </b-row>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,

  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { location } from '@/@core/mixins/location.js'

export default {
  name: 'AddSession',
  components: {
    flatPickr,
    BDropdown,

    ToastificationContent,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },
  mixins: [location],

  data() {
    return {
      userData: {},
      optionUser: [],
      subject: '',
      type: '',
      kind: '',
      description: '',
      session_report: '',
      date: '',
      is_psea: false,
      country: '',
      neighborhood_id: '',
      street_id:'',
      governorate: '',
      city: '',
      trainer_id:'',
      town: '',
      optionCountry: [],
      optiongovernorate: [],
      optionCity: [],
      optionVCity: [],
      optionType: [],
      optionKind: [],
      selectedCountry: '',
      optionTVillages: [],
      optionTown: [],
      optionStreet:[]
    }
  },

  computed: {},

  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.getCountry()
    this.getType()
  },

  methods: {
  
    getCountry() {
      this.$http.get('/api/v1/countries').then(res => {
        const country = res.data.data
        country.forEach(el => {
          this.optionCountry.push({ value: el.id, label: el.name })
        })
      })
    },
    getType() {
      this.$http.get('/api/v1/mobile-team/get-session-type-list').then(res => {
  
        this.optionType=  res.data
      })
      this.$http.get('/api/v1/mobile-team/get-session-kind-list').then(res => {
       
        this.optionKind=  res.data
      })
      this.optionUser = []
      this.$http.get('/api/v1/get-all-users').then(res => {
  
        res.data.forEach(el => {
          this.optionUser.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
        })
        // console.log(this.optionemploye);
      })
      
    },

    getoptionVgovernorate(id) {
      this.optionVgovernorate = []
      const url = `/api/v1/countries/${id}/governorates`
      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          this.optionVgovernorate.push({ value: el.id, label: el.name })
        })
      })
    },
    getoptionCity(id) {
      this.optionVCity = []
      // console.log("dd", this.optionVCity)
      const url = `/api/v1/governorates/${id}/cities`
      this.$http.get(url).then(res => {
        const country = res.data.data
        // console.log("dd",res.data.data)
        // console.log("dd", this.optionVCity)
        res.data.data.forEach(el => {
          // console.log("el", el)
          this.optionVCity.push({ value: el.id, label: el.name })
        })
      })
    },
    getoptionTgovernorate(id) {
      this.optionTgovernorate = []
      const url = `/api/v1/countries/${id}/governorates`
      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          this.optionTgovernorate.push({ value: el.id, label: el.name })
        })
      })
    },
    getoptionTown(id) {
      this.optionTown = []

      const url = `/api/v1/towns/${id}/neighborhoods`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionTown.push({ label: el.name, value: el.id })
        })
      })
    },
    // optionStreet
    getoptionStreet(id) {
      this.optionStreet = []

      const url = `/api/v1/neighborhoods/${id}/streets`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionStreet.push({ label: el.name, value: el.id })
        })
      })
    },
    getoptionTCity(id) {
      this.optionCity = []
      const url = `/api/v1/governorates/${id}/cities`
      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          this.optionCity.push({ value: el.id, label: el.name })
        })
      })
    },
    getoptionTArea(id) {
      this.optionTVillages = []
      const url = `/api/v1/cities/${id}/towns`

      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          // console.log(el)
          this.optionTVillages.push({ value: el.id, label: el.name })
        })
      })
    }, 
 

    addSession() {
      const data = {
        subject: this.subject,
        description: this.description,
        neighborhood_id:this.neighborhood_id,
        street_id:this.street_id,
        town_id:this.town,
        city_id: this.city,
        governorate_id:this.governorate,
        country_id:this.country,
        type: this.type,
        kind: this.kind,
        date: this.date,
        is_psea:this.is_psea,
      
        created_by: this.created_by,
        session_report: this.session_report,
        trainer_id: this.trainer_id,
      }
      // console.log(data);
      const url = `/api/v1/mobile-team/sessions`
      this.$http
        .post(url,data)
        .then(res => {
          this.$swal({
            title: 'تم إضافة جلسة بنجاح',

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
         this.subject = ''
       this.description= ''
        this.neighborhood_id= ''
        this.street_id=''
        this.town= ''
         this.city= ''
        this.governorate= ''
        this.country= ''
        this.type= ''
         this.kind= ''
         this.date= ''
        this.is_psea= ''
      
       this.created_by= ''
       this.session_report= ''
       this.trainer_id= ''
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: `${error.response.data.message}`,

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
